<template>
  <div class="footer" v-if="show">
    <!-- <img class="logo" :src="require('@/assets/img/logo.png')" /> -->
    <div class="text">
      <p>
        <a
          style="color: #2e2e2e"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备19045831号</a
        >
        浙网文（2018）11343-882号
      </p>
      <p>浙江再乐网络科技有限公司版权所有 ©2017-2025</p>
      <p>
        本网站仅限18岁（含18岁）以上玩家娱乐， 未满18岁不能通过本站注册认证！
      </p>
      <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当</p>
      <p>适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
      <p>再乐游戏，乐趣无限！</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
const show = ref(false);
const load = computed(() => store.state.load);
watch(load, (newV) => {
  if (newV == false) {
    setTimeout(() => {
      show.value = true;
    }, 1000);
  } else {
    show.value = false;
  }
});
</script>

<style scoped>
.footer {
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28%;
  background: #fff;
  height: 200px;
  font-size: 14px;
  color: #2e2e2e;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.logo {
  width: 130px;
  margin-right: 50px;
}
.text {
  text-align: center;
  line-height: 20px;
}
@media screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 0;
  }
  .logo {
    width: 2rem;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .text {
    font-size: 12px;
    text-align: center;
  }
}
</style>
